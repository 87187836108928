import React from "react";
import { useLanguage } from "../../components/languageProvider";
import { languages } from "../../constants/languages/languages";

const Index = () => {
  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage];
  return <div>SupportRequest</div>;
};

export default Index; 
