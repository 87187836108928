export const languages = {
  tr: {
    welcomeToDashboard: "Yönetim Paneline Hoşgeldiniz",
    homePage: "Ana Sayfa",
    successMessages: {
      saveSuccess: "Başarıyla kaydedildi.",
      updateSuccess: "Başarıyla güncellendi.",
      deleteSuccess: "Başarıyla silindi.",
    },
    errorMessages: {
      saveError: "Kaydedilirken hata oluştu. Lütfen tekrar deneyin.",
      updateError: "Güncellenirken hata oluştu. Lütfen tekrar deneyin.",
      deleteError: "Silinirken hata oluştu. Lütfen tekrar deneyin.",
    },
    printerPage: "Baskı Sayfası",
    pricePerMonth: "€ / Aylık",
    printRequestTableTitle: {
      printRequestId: "ID",
      printImage: "Resim",
      printType: "Tür",
      printUser: "Kullanıcı",
      printName: "Adı",
      printStatus: "Durum",
      printDate: "Tarih",
      printPrice: "Fiyat",
      printStatusCompleted: "Tamamlandı",
      printStatusPending: "Beklemede",
      printRequestDate: "Tarih",
      printPaymentStatus: "Ödeme Durumu",
      printPaymentStatusPaid: "Ödendi",
      printPaymentStatusUnpaid: "Ödenmedi",
      viewPrices: "Fiyatları Görüntüle",
      deletePrintRequest: "Baskı Talebini Sil",
    },
    printPriceTableTitle: {
      printName: "Adı",
      printDescription: "Açıklama",
      printPrice: "Fiyat",
      addPrice: "Fiyat Ekle",
      editPrice: "Fiyat Düzenle",
      deletePrice: "Fiyat Sil",
    },
    addPrintTable: {
      printNameDe: "Adı (Almanca)",
      printNameEn: "Adı (İngilizce)",
      printNameTr: "Adı (Türkçe)",
      printNameFr: "Adı (Fransızca)",
      printNameIt: "Adı (İtalyanca)",
      printNameNl: "Adı (Hollandaca)",
      printDescription: "Açıklama",
      printPrice: "Fiyat",
      printSave: "Kaydet",
      printCancel: "İptal",
    },
    packages: "Paketler",
    addPackage: "Paket Ekle",
    savePackage: "Paketi Kaydet",
    updatePackage: "Paketi Güncelle",
    NewlyAddedImages: "Yeni Eklenen Resimler",
    editPackage: "Paketi Düzenle",
    deletePackage: "Paketi Sil",
    packageDeleteInfo:
      "Paket silme işlemi pakete ait tüm resimleri silecektir. Devam etmek istediğinize emin misiniz?",
    packageSaveSuccess: "Paket başarıyla eklendi.",
    packageSaveError: "Paket eklenirken hata oluştu. Lütfen tekrar deneyin.",
    packageUpdateSuccess: "Paket başarıyla güncellendi.",
    packageType0: "Gümüş (Tekli)",
    packageType1: "Altın (Çoklu)",
    packageType2: "Platin (Her ikisi)",
    packagesTableTitle: {
      packageId: "ID",
      packageName: "Adı",
      packageNameTr: "Adı (Türkçe)",
      packageNameDe: "Adı (Almanca)",
      packageNameEn: "Adı (İngilizce)",
      packageNameFr: "Adı (Fransızca)",
      packageNameIt: "Adı (İtalyanca)",
      packageNameNl: "Adı (Hollandaca)",
      packageRegion: "Bölge",
      packagePrice: "Fiyat",
      packageDescription: "Açıklama",
      package3Monthly: "3 Aylık",
      package6Monthly: "6 Aylık",
      packageYearly: "Yıllık",
      package2Yearly: "2 Yıllık",
      packageLanguage: "Paket Dili",
      packageDetails: "Paket Detayları",
      packageInformation: "Paket Bilgileri",
      editPackage: "Paketi Düzenle",
    },
    products: "Ürünler",
    productDeleteInfo:
      "Ürün silme işlemi ürüne ait tüm resimleri silecektir. Devam etmek istediğinize emin misiniz?",
    productDelete: "Sil",
    productCancel: "İptal",
    productDeleteSuccess: "Ürün başarıyla silindi.",
    productDeleteError: "Ürün silinirken hata oluştu. Lütfen tekrar deneyin.",
    close: "Kapat",
    addBackgroundImages: {
      addImage: "Resim Ekle",
      addBackgroundImage: "Arka Plan Resmi Ekle",
      backgroundImageSize: "Arka plan resmi boyutu 1.414:1 oranında olmalıdır.",
      backgroundImageSave: "Kaydet",
      backgroundImageCancel: "İptal",
      backgroundImagesSaveSuccess: "Arka plan resmi başarıyla eklendi.",
      backgroundImageSaveError:
        "Arka plan resmi eklenirken hata oluştu. Lütfen tekrar deneyin.",
      selectBackgroundImage: "Arka Plan Resmi Seç",
      selectUserError: "Lütfen bir kullanıcı seçiniz",
      selectBackgroundImageError: "Lütfen bir arka plan resmi seçiniz",
      singleBackgroundImage: "Tekli Arka Plan Resmi",
      multipleBackgroundImages: "Çoklu Arka Plan Resimleri",
      backgroundImages: "Arka Plan Resimleri",
      backgroundImage: "Arka Plan Resmi",
      deleteImageWarning: "Bu resmi silmek istediğinizden emin misiniz?",
      deleteBackgroundImageSuccess: "Arka plan resmi başarıyla silindi.",
      deleteBackgroundImageError: "Arka plan resmi silinirken hata oluştu.",
      yes: "Evet",
      no: "Hayır",
    },

    addProductItems: {
      addImage: "Resim Ekle",
      addProduct: "Ürün Ekle",
      productImageSize: "Ürün resmi boyutu 1000x1000 piksel olmalıdır.",
      productName: "Ürün Adı",
      category: "Kategori",
      subCategory: "Alt Kategori",
      productDescription: "Ürün Açıklaması",
      productPrice: "Fiyat",
      productStock: "Stok",
      productSave: "Kaydet",
      productCancel: "İptal",
    },
    addCategoryItems: {
      addImage: "Resim ekle",
      addSubCategoryImages: "Alt Kategori Resimleri Ekle",
      addCategory: "Kategori ekle",
      editCategory: "Kategori düzenle",
      reviewCategory: "Kategori İncele",
      categoryImageSize: "Kategori resmi boyutu 1000x1000 piksel olmalıdır.",
      categoryName: "Kategori Adı",
      category: "Kategori",
      subCategory: "Alt Kategori",
      addSubCategory: "Alt Kategori Ekle",
      categoryDescription: "Kategori Açıklaması",
      categoryLanguage: "Kategori Dili",
      categorySave: "Kaydet",
      categoryCancel: "İptal",
      categoryNameTr: "Kategori Adı (Türkçe)",
      categoryNameDe: "Kategori Adı (Almanca)",
      categoryNameEn: "Kategori Adı (İngilizce)",
      categoryNameFr: "Kategori Adı (Fransızca)",
      categoryNameIt: "Kategori Adı (İtalyanca)",
      categoryNameNl: "Kategori Adı (Hollandaca)",
      categorySaveError:
        "Kategori eklenirken hata oluştu. Lütfen tekrar deneyin.",
      categoryUpdateError:
        "Kategori güncellenirken hata oluştu. Lütfen tekrar deneyin.",
      categorySaveSuccess: "Kategori başarıyla eklendi.",
      categoryUpdateSuccess: "Kategori başarıyla güncellendi.",

      subCategoryDeleteInfo:
        "Alt Kategori silme işlemi kategoriye ait tüm resimleri silecektir. Devam etmek istediğinize emin misiniz?",
      categoryDeleteInfo:
        "Kategori silme işlemi kategoriye ait tüm alt kategorileri ve resimleri silecektir. Devam etmek istediğinize emin misiniz?",
      deleteSubCategoryConfirm: "Onaylıyor musunuz?",
      deleteSubCategory: "Sil",
      deleteSubCategoryCancel: "İptal",
      categoryDeleteError:
        "Kategori silinirken hata oluştu. Lütfen tekrar deneyin.",
      categoryDeleteSuccess: "Kategori başarıyla silindi.",
      fileUploadError: "Dosya yüklenirken hata oluştu. Lütfen tekrar deneyin.",
      fileUploadSuccess: "Dosya başarıyla yüklendi.",
      categoryImageRelationError:
        "Kategori resim ilişiklendirme başarısız oldu. Lütfen tekrar deneyin.",
      categoryImageRelationSuccess: "Kategori resim ilişiklendirme başarılı.",
      fileExtensionError: "Dosya uzantısı jpg, jpeg, png olmalıdır.",
      fileAspectRatioError: "Dosya en boy oranı 1:1 olmalıdır.",
    },
    showSubCategories: "Alt Kategorileri Göster",
    showCategories: "Kategorileri Göster",
    productsTableTitle: {
      productId: "ID",
      productName: "Ürün Adı",
      productUser: "Kullanıcı",
      productDescription: "Açıklama",
      productImage: "Resim",
      category: "Kategori",
      subCategory: "Alt Kategori",
      productStatus: "Durum",
    },
    pending: "Bekleyen",
    approved: "Onaylanan",
    rejected: "Reddedilen",
    Areyousurewanttodeletethisimage:
      "Bu resmi silmek istediğinizden emin misiniz?",
    category: "Kategori",
    subCategory: "Alt Kategori",
    subCategories: "Alt Kategoriler",
    addCategory: "Kategori Ekle",
    addSubCategory: "Alt Kategori Ekle",
    editSubCategory: "Alt Kategori Düzenle",
    editCategory: "Kategori Düzenle",
    deleteCategory: "Kategori Sil",
    reviewCategory: "Kategori İncele",
    categoryImages: "Kategori Resimleri",
    categoryImage: "Kategori Resmi",
    subCategoryReview: "Alt Kategori İncele",
    subCategoryImages: "Alt Kategori Resimleri",
    errorChangingVisibility: "Görünürlük değiştirilirken hata oluştu.",
    successChangingVisibility: "Görünürlük başarıyla değiştirildi.",
    successDeletingImage: "Resim başarıyla silindi.",
    errorDeletingImage: "Resim silinirken hata oluştu.",
    addSubCategoryImages: "Alt Kategori Resimleri Ekle",
    categories: "Kategoriler",
    categoriesAndImages: "Kategoriler/Resimler",
    sendedCategoryImages: "Gönderilen Kategori Resimleri",
    uploadEditedImage: "Düzenlenen Resmi Yükle",
    downloadSendedImage: "Gönderilen Resmi İndir",

    categoryTableTitle: {
      categoryId: "ID",
      categoryName: "Kategori Adı",
      categoryDescription: "Açıklama",
      categorySerial: "Sıra",
      language: "Dil",
    },
    dealer: "Bayi",
    dealerTexts: {
      dealers: "Bayiler",
      yes: "Evet",
      no: "Hayır",
      addDealer: "Bayi Ekle",
      deleteDealer: "Bayi Sil",
      deleteDealerMessage: "Bayiyi silmek istediğinize emin misiniz?",
      deleteDealerSuccess: "Bayi başarıyla silindi.",
      deleteDealerError: "Bayi silinirken hata oluştu. Lütfen tekrar deneyin.",

      search: "Ara",
      previous: "Önceki",
      next: "Sonraki",
      dealerAddress: "Adres",
      dealerTableTitle: {
        dealerId: "ID",
        dealerAddress: "Adres",
        dealerPhone: "Telefon",
        dealerName: "Bayi Adı",
      },
      dealerCity: "Şehir",
      dealerCountry: "Ülke",
      dealerEmail: "E-posta",
      dealerName: "Bayi Adı",
      dealerPhone: "Telefon",
      dealerPostalCode: "Posta Kodu",
      dealerCountry: "Ülke",
      addZipCode: "Posta Kodu Ekle",
      dealerSave: "Kaydet",
      dealerCancel: "İptal",
      dealerState: "Durum",
      dealerTitle: "Bayiler",
      editDealer: "Bayi Düzenle",
      updateDealer: "Bayi Güncelle",
      dealerSaveError: "Bayi eklenirken hata oluştu. Lütfen tekrar deneyin.",
      dealerUpdateError:
        "Bayi güncellenirken hata oluştu. Lütfen tekrar deneyin.",
      dealerSaveSuccess: "Bayi başarıyla eklendi.",
      dealerUpdateSuccess: "Bayi başarıyla güncellendi.",
    },
    analysis: "Analiz",
    payments: "Ödemeler",
    users: "Kullanıcılar",
    menu: "Menü",
    saveStatus: {
      imageSaveError: "Resim kaydedilirken hata oluştu. Lütfen tekrar deneyin.",
      userSaveError:
        "Kullanıcı kaydedilirken hata oluştu. Lütfen tekrar deneyin.",
      relationSaveError:
        "İlişki kaydedilirken hata oluştu. Lütfen tekrar deneyin.",
      userSaveSuccess: "Kullanıcı başarıyla eklendi.",
      userUpdateSuccess: "Kullanıcı başarıyla güncellendi.",
      deleteUserMessage: "Kullanıcıyı silmek istediğinize emin misiniz?",
      deleteUserSuccess: "Kullanıcı başarıyla silindi.",
      deleteUserError:
        "Kullanıcı silinirken hata oluştu. Lütfen tekrar deneyin.",
    },
    userTableTitle: {
      id: "ID",
      name: "Ad",
      surname: "Soyad",
      email: "E-posta",
      companyName: "Firma Adı",
      companyNumber: "Firma Numarası",
      role: "Rol",
      freeTrial: "Ücretsiz Deneme",
      userName: "Kullanıcı Adı",
      userPassword: "Şifre",
      userImage: "Kullanıcı Resmi",
      userTaxNumber: "Vergi Numarası",
      userBackgrondImages: "Kullanıcı Arka Plan Resimleri",
      userRegistered: "Kayıt Tarihi",
      phone: "Telefon",
      editUser: "Kullanıcıyı Düzenle",
      addUser: "Kullanıcı Ekle",
      updateUser: "Kullanıcıyı Güncelle",
      deleteUser: "Kullanıcıyı Sil",
      userIBAN: "IBAN",
      userAccountNumber: "Banka Hesap Numarası",
      userBankName: "Banka Adı",
      userBankAccountOwnerName: "Banka Hesap Sahibi Adı",
      userBankAccountOwnerSurname: "Banka Hesap Sahibi Soyadı",
      userZipCode: "Posta Kodu",
    },
    userModalTable: {
      userDetails: "Kullanıcı Detayları",
      userInformation: "Kullanıcı Bilgileri",
      userBackgrondImages: "Arka Plan Resimleri",
      id: "ID",
      name: "Ad",
      surname: "Soyad",
      userName: "Kullanıcı Adı",
      password: "Şifre",
      email: "E-posta",
      phone: "Telefon",
      role: "Rol",
      companyName: "Firma Adı",
      freeTrial: "Deneme Sürümü",
      freeTrialYes: "Evet",
      freeTrialNo: "Hayır",
      registerDate: "Kayıt Tarihi",
      userBankInfo: "Banka Bilgileri",
      userTaxNumber: "Vergi Numarası",
      userIBAN: "IBAN",
      userBankAccountOwner: "Banka Hesap Sahibi",
      userAccountNumber: "Banka Hesap Numarası",
      userBankName: "Banka Adı",
      dealerInfo: "Bayi Bilgileri",
      dealerName: "Bayi Adı",
      dealerAddress: "Adres",
      dealerPhone: "Telefon",
      dealerPostalCode: "Posta Kodu",
    },

    paymentTableTitle: {
      paymentId: "ID",
      userId: "Kullanıcı ID",
      userName: "Kullanıcı Adı",
      paymentAmount: "Ödeme Miktarı",
      paymentStatus: "Ödeme Durumu",
      paymentDate: "Ödeme Tarihi",
      paymentDescription: "Ödeme Açıklaması",
      description: "Açıklama",
    },
    loginPage: {
      username: "Kullanıcı Adı",
      password: "Şifre",
      login: "Giriş Yap",
      loginSuccess: "Giriş başarılı",
      loginError: "Kullanıcı adı veya şifre hatalı",
      loginWarning: "Kullanıcı adı veya şifre boş olamaz",
      selectLanguage: "Dil Seçiniz",
    },
    supportRequests: "Destek Talepleri",
    sendedImages: "Gönderilen Resimler",
    userBackgrondImages: "Arka Plan Resimleri",
    sendedProducts: "Gönderilen Ürünler",
    sendedProductsFromUsers: "Kullanıcılardan Gönderilen Ürünler",
    Images: "Resimler",
    search: "Ara",
    previous: "Önceki",
    next: "Sonraki",
    selectLanguages: "Dil Seç",
    addBusiness: "İşletme Ekle",
    editBusiness: "İşletme Düzenle",
    addProduct: "Ürün Ekle",
    editProduct: "Ürün Düzenle",
    deleteProduct: "Ürün Sil",
    showProductImage: "Ürün Resimini Göster",
    showImages: "Resimleri Göster",
    noImageFound: "Resim Bulunamadı",
    productImageSize: "Ürün resmi boyutu 1000x1000 piksel olmalıdır.",
    addImage: "Resim Ekle",
    productName: "Ürün Adı",
    yes: "Evet",
    no: "Hayır",
  },
  en: {
    welcomeToDashboard: "Welcome to Dashboard",
    homePage: "Home Page",
    successMessages: {
      saveSuccess: "Saved successfully.",
      updateSuccess: "Updated successfully.",
      deleteSuccess: "Deleted successfully.",
    },
    errorMessages: {
      saveError: "An error occurred while saving. Please try again.",
      updateError: "An error occurred while updating. Please try again.",
      deleteError: "An error occurred while deleting. Please try again.",
    },
    printerPage: "Printer Page",
    pricePerMonth: "€ / Monthly",
    printRequestTableTitle: {
      printRequestId: "ID",
      printImage: "Image",
      printType: "Type",
      printUser: "User",
      printName: "Name",
      printStatus: "Status",
      printDate: "Date",
      printPrice: "Price",
      printStatusCompleted: "Completed",
      printStatusPending: "Pending",
      printRequestDate: "Date",
      printPaymentStatus: "Payment Status",
      printPaymentStatusPaid: "Paid",
      printPaymentStatusUnpaid: "Unpaid",
      viewPrices: "View Prices",
      deletePrintRequest: "Delete Print Request",
    },
    printPriceTableTitle: {
      printName: "Name",
      printDescription: "Description",
      printPrice: "Price",
      addPrice: "Add Price",
      editPrice: "Edit Price",
      deletePrice: "Delete Price",
    },
    addPrintTable: {
      printNameDe: "Name (German)",
      printNameEn: "Name (English)",
      printNameTr: "Name (Turkish)",
      printNameFr: "Name (French)",
      printNameIt: "Name (Italian)",
      printNameNl: "Name (Dutch)",
      printDescription: "Description",
      printPrice: "Price",
      printSave: "Save",
      printCancel: "Cancel",
    },
    packages: "Packages",
    addPackage: "Add Package",
    savePackage: "Save Package",
    updatePackage: "Update Package",
    NewlyAddedImages: "Newly Added Images",
    editPackage: "Edit Package",
    deletePackage: "Delete Package",
    packageDeleteInfo:
      "Deleting package will delete all images related to package. Are you sure you want to continue?",
    packageSaveSuccess: "Package added successfully.",
    packageSaveError:
      "An error occurred while adding the package. Please try again.",
    packageUpdateSuccess: "Package updated successfully.",
    packageType0: "Silver (Single)",
    packageType1: "Gold (Multiple)",
    packageType2: "Platinum (Both)",
    packagesTableTitle: {
      packageId: "ID",
      packageName: "Name",
      packageNameTr: "Name (Turkish)",
      packageNameDe: "Name (German)",
      packageNameEn: "Name (English)",
      packageNameFr: "Name (French)",
      packageNameIt: "Name (Italian)",
      packageNameNl: "Name (Dutch)",
      packageRegion: "Region",
      packagePrice: "Price",
      packageDescription: "Description",
      package3Monthly: "3 Monthly",
      package6Monthly: "6 Monthly",
      packageYearly: "Yearly",
      package2Yearly: "2 Yearly",
      packageLanguage: "Package Language",
      packageDetails: "Package Details",
      packageInformation: "Package Information",
      editPackage: "Edit Package",
    },
    products: "Products",
    productDeleteInfo:
      "Deleting product will delete all images related to product. Are you sure you want to continue?",
    productDelete: "Delete",
    productCancel: "Cancel",
    productDeleteSuccess: "Product deleted successfully.",
    productDeleteError:
      "An error occurred while deleting the product. Please try again.",
    close: "Close",
    addBackgroundImages: {
      addImage: "Add Image",
      addBackgroundImage: "Add Background Image",
      backgroundImageSize: "Background image size should be 1.414:1 ratio.",
      backgroundImageSave: "Save",
      backgroundImageCancel: "Cancel",
      backgroundImagesSaveSuccess: "Background image added successfully.",
      backgroundImageSaveError:
        "An error occurred while adding the background image. Please try again.",
      selectBackgroundImage: "Select Background Image",
      selectUserError: "Please select a user",
      selectBackgroundImageError: "Please select a background image",
      singleBackgroundImage: "Single Background Image",
      multipleBackgroundImages: "Multiple Background Images",
      backgroundImages: "Background Images",
      backgroundImage: "Background Image",
      deleteImageWarning: "Are you sure want to delete this image?",
      deleteBackgroundImageSuccess: "Background image deleted successfully.",
      deleteBackgroundImageError: "An error occurred while deleting the image.",
      yes: "Yes",
      no: "No",
    },

    addProductItems: {
      addImage: "Add Image",
      addProduct: "Add Product",
      productImageSize: "Product image size should be 1000x1000 pixels.",
      productName: "Product Name",
      category: "Category",
      subCategory: "Sub Category",
      productDescription: "Product Description",
      productPrice: "Price",
      productStock: "Stock",
      productSave: "Save",
      productCancel: "Cancel",
    },
    addCategoryItems: {
      addImage: "Add Image",
      addSubCategoryImages: "Add Sub Category Images",
      addCategory: "Add Category",
      editCategory: "Edit Category",
      reviewCategory: "Review Category",
      categoryImageSize: "Category image size should be 1000x1000 pixels.",
      categoryName: "Category Name",
      category: "Category",
      subCategory: "Sub Category",
      categoryDescription: "Category Description",
      addSubCategory: "Add Sub Category",
      categoryLanguage: "Category Language",
      categorySave: "Save",
      categoryCancel: "Cancel",

      categoryNameTr: "Category Name (Turkish)",
      categoryNameDe: "Category Name (German)",
      categoryNameEn: "Category Name (English)",
      categoryNameFr: "Category Name (French)",
      categoryNameIt: "Category Name (Italian)",
      categoryNameNl: "Category Name (Dutch)",
      categorySaveError:
        "An error occurred while adding the category. Please try again.",
      categoryUpdateError:
        "An error occurred while updating the category. Please try again.",
      categorySaveSuccess: "Category added successfully.",
      categoryUpdateSuccess: "Category updated successfully.",
      subCategoryDeleteInfo:
        "Deleting sub category will delete all images related to category. Are you sure you want to continue?",
      categoryDeleteInfo:
        "Deleting category will delete all sub categories and images related to category. Are you sure you want to continue?",
      deleteSubCategoryConfirm: "Are you sure?",
      deleteSubCategory: "Delete",
      deleteSubCategoryCancel: "Cancel",

      categoryDeleteError:
        "An error occurred while deleting the category. Please try again.",
      categoryDeleteSuccess: "Category deleted successfully.",
      fileUploadError:
        "An error occurred while uploading the file. Please try again.",
      fileUploadSuccess: "File uploaded successfully.",
      categoryImageRelationError:
        "Category image relation failed. Please try again.",
      categoryImageRelationSuccess: "Category image relation successful.",
      fileExtensionError: "File extension should be jpg, jpeg, png.",
      fileAspectRatioError: "File aspect ratio should be 1:1.",
    },
    showSubCategories: "Show Sub Categories",
    showCategories: "Show Categories",
    productsTableTitle: {
      productId: "ID",
      productName: "Name",
      productUser: "User",
      productDescription: "Description",
      productImage: "Image",
      category: "Category",
      subCategory: "Sub Category",
      productStatus: "Status",
    },
    pending: "Pending",
    approved: "Approved",
    rejected: "Rejected",
    Areyousurewanttodeletethisimage: "Are you sure want to delete this image?",
    category: "Category",
    subCategory: "Sub Category",
    subCategories: "Sub Categories",
    addCategory: "Add Category",
    addSubCategory: "Add Sub Category",
    editSubCategory: "Edit Sub Category",
    editCategory: "Edit Category",
    deleteCategory: "Delete Category",
    reviewCategory: "Review Category",
    categoryImages: "Category Images",
    categoryImage: "Category Image",
    subCategoryReview: "Sub Category Review",
    subCategoryImages: "Sub Category Images",
    errorChangingVisibility: "An error occurred while changing visibility.",
    successChangingVisibility: "Visibility changed successfully.",
    successDeletingImage: "Image deleted successfully.",
    addSubCategoryImages: "Add Sub Category Images",
    categories: "Categories",
    categoriesAndImages: "Categories/Images",
    sendedCategoryImages: "Sended Category Images",
    uploadEditedImage: "Upload Edited Image",
    downloadSendedImage: "Download Sended Image",
    categoryTableTitle: {
      categoryId: "ID",
      categoryName: "Category Name",
      categoryDescription: "Description",
      categorySerial: "Serial",
      language: "Language",
    },

    dealer: "Dealer",
    dealerTexts: {
      dealers: "Dealers",
      yes: "Yes",
      no: "No",
      addDealer: "Add Dealer",
      deleteDealer: "Delete Dealer",
      deleteDealerMessage: "Are you sure you want to delete the dealer?",
      deleteDealerSuccess: "Dealer deleted successfully.",
      deleteDealerError:
        "An error occurred while deleting the dealer. Please try again.",
      search: "Search",
      previous: "Previous",
      next: "Next",
      dealerAddress: "Address",
      dealerTableTitle: {
        dealerId: "ID",
        dealerAddress: "Address",
        dealerPhone: "Phone",
        dealerName: "Dealer Name",
      },
      dealerCity: "City",
      dealerCountry: "Country",
      dealerEmail: "Email",
      dealerName: "Dealer Name",
      dealerPhone: "Phone",
      dealerPostalCode: "Postal Code",
      dealerCountry: "Country",
      addZipCode: "Add Zip Code",
      dealerSave: "Save",
      dealerCancel: "Cancel",
      dealerState: "State",
      dealerTitle: "Dealers",
      editDealer: "Edit Dealer",
      updateDealer: "Update Dealer",
      dealerSaveError:
        "An error occurred while adding the dealer. Please try again.",
      dealerUpdateError:
        "An error occurred while updating the dealer. Please try again.",
      dealerSaveSuccess: "Dealer added successfully.",
      dealerUpdateSuccess: "Dealer updated successfully.",
    },
    analysis: "Analysis",
    payments: "Payments",
    users: "Users",
    menu: "Menu",
    saveStatus: {
      imageSaveError:
        "An error occurred while saving the image. Please try again.",
      userSaveError:
        "An error occurred while saving the user. Please try again.",
      relationSaveError:
        "An error occurred while saving the relation. Please try again.",
      userSaveSuccess: "User added successfully.",
      userUpdateSuccess: "User updated successfully.",
      deleteUserMessage: "Are you sure you want to delete the user?",
      deleteUserSuccess: "User deleted successfully.",
      deleteUserError:
        "An error occurred while deleting the user. Please try again.",
    },
    userTableTitle: {
      id: "ID",
      name: "Name",
      surname: "Surname",
      email: "Email",
      companyName: "Company Name",
      companyNumber: "Company Number",
      role: "Role",
      freeTrial: "Free Trial",
      userName: "User Name",
      userPassword: "Password",
      userImage: "User Image",
      userTaxNumber: "Tax Number",
      userBackgrondImages: "User Background Images",
      userRegistered: "Registered Date",
      phone: "Phone",
      editUser: "Edit User",
      addUser: "Add User",
      updateUser: "Update User",
      deleteUser: "Delete User",
      userIBAN: "IBAN",
      userAccountNumber: "Bank Account Number",
      userBankName: "Bank Name",
      userBankAccountOwnerName: "Bank Account Owner Name",
      userBankAccountOwnerSurname: "Bank Account Owner Surname",
      userZipCode: "Zip Code",
    },
    userModalTable: {
      userDetails: "User Details",
      userInformation: "User Information",
      userBackgrondImages: "Background Images",
      id: "ID",
      name: "Name",
      surname: "Surname",
      userName: "User Name",
      password: "Password",
      email: "Email",
      phone: "Phone",
      role: "Role",
      companyName: "Company Name",
      freeTrial: "Free Trial",
      freeTrialYes: "Yes",
      freeTrialNo: "No",
      registerDate: "Registered Date",
      userBankInfo: "Bank Info",
      userTaxNumber: "Tax Number",
      userIBAN: "IBAN",
      userBankAccountOwner: "Bank Account Owner",
      userAccountNumber: "Bank Account Number",
      userBankName: "Bank Name",
      dealerInfo: "Dealer Info",
      dealerName: "Dealer Name",
      dealerAddress: "Address",
      dealerPhone: "Phone",
      dealerPostalCode: "Postal Code",
    },
    paymentTableTitle: {
      paymentId: "ID",
      userId: "User ID",
      userName: "User Name",
      paymentAmount: "Payment Amount",
      paymentStatus: "Payment Status",
      paymentDate: "Payment Date",
      paymentDescription: "Payment Description",
      description: "Description",
    },
    loginPage: {
      username: "Username",
      password: "Password",
      login: "Login",
      loginSuccess: "Login successful",
      loginError: "Username or password is incorrect",
      loginWarning: "Username or password cannot be empty",
      selectLanguage: "Select Language",
    },

    supportRequests: "Support Requests",
    sendedImages: "Sended Images",
    userBackgrondImages: "User Background Images",
    sendedProducts: "Sended Products",
    sendedProductsFromUsers: "Sended Products From Users",
    Images: "Images",
    search: "Search",
    previous: "Previous",
    next: "Next",
    selectLanguages: "Select Language",
    addBusiness: "Add Business",
    editBusiness: "Edit Business",
    addProduct: "Add Product",
    editProduct: "Edit Product",
    deleteProduct: "Delete Product",
    showProductImage: "Show Product Image",
    showImages: "Show Images",

    noImageFound: "No Image Found",
    productImageSize: "Product image size should be 1000x1000 pixels.",
    addImage: "Add Image",
    productName: "Product Name",
    yes: "Yes",
    no: "No",
  },
  de: {
    welcomeToDashboard: "Willkommen im Dashboard",
    homePage: "Startseite",
    successMessages: {
      saveSuccess: "Erfolgreich gespeichert.",
      updateSuccess: "Erfolgreich aktualisiert.",
      deleteSuccess: "Erfolgreich gelöscht.",
    },
    errorMessages: {
      saveError:
        "Beim Speichern ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      updateError:
        "Beim Aktualisieren ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      deleteError:
        "Beim Löschen ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
    },
    printerPage: "Druckerseite",
    pricePerMonth: "€ / Monatlich",
    printRequestTableTitle: {
      printRequestId: "ID",
      printImage: "Bild",
      printType: "Typ",
      printUser: "Benutzer",
      printName: "Name",
      printStatus: "Status",
      printDate: "Datum",
      printPrice: "Preis",
      printStatusCompleted: "Abgeschlossen",
      printStatusPending: "Ausstehend",
      printRequestDate: "Datum",
      printPaymentStatus: "Zahlungsstatus",
      printPaymentStatusPaid: "Bezahlt",
      printPaymentStatusUnpaid: "Unbezahlt",
      viewPrices: "Preise anzeigen",
      deletePrintRequest: "Druckanfrage löschen",
    },
    printPriceTableTitle: {
      printName: "Name",
      printDescription: "Beschreibung",
      printPrice: "Preis",
      addPrice: "Preis hinzufügen",
      editPrice: "Preis bearbeiten",
      deletePrice: "Preis löschen",
    },
    addPrintTable: {
      printNameDe: "Name (Deutsch)",
      printNameEn: "Name (Englisch)",
      printNameTr: "Name (Türkisch)",
      printNameFr: "Name (Französisch)",
      printNameIt: "Name (Italienisch)",
      printNameNl: "Name (Niederländisch)",
      printDescription: "Beschreibung",
      printPrice: "Preis",
      printSave: "Speichern",
      printCancel: "Abbrechen",
    },
    packages: "Preiliste",
    addPackage: "Preilist hinzufügen",
    savePackage: "Preilist speichern",
    updatePackage: "Preilist aktualisieren",
    NewlyAddedImages: "Neu hinzugefügte Bilder",
    editPackage: "Preilist bearbeiten",
    deletePackage: "Preilist löschen",
    packageSaveSuccess: "Paket erfolgreich hinzugefügt.",
    packageDeleteInfo:
      "Durch das Löschen des Preilists werden alle mit dem Paket verbundenen Bilder gelöscht. Möchten Sie wirklich fortfahren?",
    packageSaveError:
      "Beim Hinzufügen des Preilists ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
    packageUpdateSuccess: "Preilist erfolgreich aktualisiert.",
    packageType0: "Silber (Einzel)",
    packageType1: "Gold (Mehrere)",
    packageType2: "Platin (Beide)",

    packagesTableTitle: {
      packageId: "ID",
      packageName: "Name",
      packageNameTr: "Name (Türkisch)",
      packageNameDe: "Name (Deutsch)",
      packageNameEn: "Name (Englisch)",
      packageNameFr: "Name (Französisch)",
      packageNameIt: "Name (Italienisch)",
      packageNameNl: "Name (Niederländisch)",
      packageRegion: "Region",
      packagePrice: "Preis",
      packageDescription: "Beschreibung",
      package3Monthly: "3 Monatlich",
      package6Monthly: "6 Monatlich",
      packageYearly: "Jährlich",
      package2Yearly: "2 Jährlich",
      packageLanguage: "Paket Sprache",
      packageDetails: "Paket Details",
      packageInformation: "Paket Informationen",
      editPackage: "Paket bearbeiten",
    },
    menu: "Menü",
    products: "Kunden Werbung",
    productDeleteInfo:
      "Durch das Löschen des Produkts werden alle mit dem Produkt verbundenen Bilder gelöscht. Möchten Sie wirklich fortfahren?",
    productDelete: "Löschen",
    productCancel: "Abbrechen",
    productDeleteSuccess: "Produkt erfolgreich gelöscht.",
    productDeleteError:
      "Beim Löschen des Produkts ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
    close: "Schließen",
    addBackgroundImages: {
      addImage: "Bild hinzufügen",
      addBackgroundImage: "Hintergrundbild hinzufügen",
      backgroundImageSize:
        "Die Größe des Hintergrundbildes sollte ein Seitenverhältnis von 1.414:1 haben.",
      backgroundImageSave: "Speichern",
      backgroundImageCancel: "Abbrechen",
      backgroundImagesSaveSuccess: "Hintergrundbild erfolgreich hinzugefügt.",
      backgroundImageSaveError:
        "Beim Hinzufügen des Hintergrundbildes ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      selectBackgroundImage: "Hintergrundbild auswählen",
      selectUserError: "Bitte wählen Sie einen Benutzer aus",
      selectBackgroundImageError: "Bitte wählen Sie ein Hintergrundbild aus",
      singleBackgroundImage: "Einzelnes Hintergrundbild",
      multipleBackgroundImages: "Mehrere Hintergrundbilder",
      backgroundImages: "Hintergrundbilder",
      backgroundImage: "Hintergrundbild",
      deleteImageWarning: "Möchten Sie dieses Bild wirklich löschen?",
      deleteBackgroundImageSuccess: "Hintergrundbild erfolgreich gelöscht.",
      deleteBackgroundImageError: "Fehler beim Löschen des Bildes.",
      yes: "Ja",
      no: "Nein",
    },

    addProductItems: {
      addImage: "Bild hinzufügen",
      addProduct: "Produkt hinzufügen",
      productImageSize: "Die Produktbildgröße sollte 1000x1000 Pixel betragen.",
      productName: "Produktname",
      category: "Kategorie",
      subCategory: "Unterkategorie",
      productDescription: "Produktbeschreibung",
      productPrice: "Preis",
      productStock: "Bestand",
      productSave: "Speichern",
      productCancel: "Abbrechen",
    },
    addCategoryItems: {
      addImage: "Bild hinzufügen",
      addSubCategoryImages: "Unterkategorie Bilder hinzufügen",
      addCategory: "Kategorie hinzufügen",
      editCategory: "Kategorie bearbeiten",
      reviewCategory: "Kategorie überprüfen",
      categoryImageSize:
        "Die Kategoriebildgröße sollte 1000x1000 Pixel betragen.",
      categoryName: "Kategoriename",
      category: "Kategorie",
      subCategory: "Unterkategorie",
      categoryDescription: "Kategoriebeschreibung",
      addSubCategory: "Unterkategorie hinzufügen",
      categoryLanguage: "Kategoriesprache",
      categorySave: "Speichern",
      categoryCancel: "Abbrechen",
      categoryNameTr: "Kategoriename (Türkisch)",
      categoryNameDe: "Kategoriename (Deutsch)",
      categoryNameEn: "Kategoriename (Englisch)",
      categoryNameFr: "Kategoriename (Französisch)",
      categoryNameIt: "Kategoriename (Italienisch)",
      categoryNameNl: "Kategoriename (Niederländisch)",
      categorySaveError:
        "Beim Hinzufügen der Kategorie ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      categoryUpdateError:
        "Beim Aktualisieren der Kategorie ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      categorySaveSuccess: "Kategorie erfolgreich hinzugefügt.",
      categoryUpdateSuccess: "Kategorie erfolgreich aktualisiert.",
      subCategoryDeleteInfo:
        "Das Löschen der Unterkategorie löscht alle Bilder, die mit der Kategorie zusammenhängen. Möchten Sie wirklich fortfahren?",
      categoryDeleteInfo:
        "Das Löschen der Kategorie löscht alle Unterkategorien und Bilder, die mit der Kategorie zusammenhängen. Möchten Sie wirklich fortfahren?",
      deleteSubCategoryConfirm: "Sind Sie sicher?",
      deleteSubCategory: "Löschen",
      deleteSubCategoryCancel: "Abbrechen",

      categoryDeleteError:
        "Beim Löschen der Kategorie ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      categoryDeleteSuccess: "Kategorie erfolgreich gelöscht.",
      fileUploadError:
        "Beim Hochladen der Datei ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      fileUploadSuccess: "Datei erfolgreich hochgeladen.",
      categoryImageRelationError:
        "Kategoriebildrelation fehlgeschlagen. Bitte versuchen Sie es erneut.",
      categoryImageRelationSuccess: "Kategoriebildrelation erfolgreich.",
      fileExtensionError: "Dateierweiterung sollte jpg, jpeg, png sein.",
      fileAspectRatioError: "Dateiaspektverhältnis sollte 1:1 sein.",
    },
    showSubCategories: "Unterkategorien anzeigen",
    showCategories: "Kategorien anzeigen",
    productsTableTitle: {
      productId: "ID",
      productName: "Name",
      productUser: "Benutzer",
      productDescription: "Beschreibung",
      productImage: "Bild",
      category: "Kategorie",
      subCategory: "Unterkategorie",
      productStatus: "Status",
    },
    pending: "Ausstehend",
    approved: "Genehmigt",
    rejected: "Abgelehnt",
    Areyousurewanttodeletethisimage:
      "Möchten Sie dieses Bild wirklich löschen?",
    category: "Kategorie",
    subCategory: "Unterkategorie",
    subCategories: "Unterkategorien",
    addCategory: "Kategorie hinzufügen",
    addSubCategory: "Unterkategorie hinzufügen",
    editSubCategory: "Unterkategorie bearbeiten",
    editCategory: "Kategorie bearbeiten",
    deleteCategory: "Kategorie löschen",
    reviewCategory: "Kategorie überprüfen",
    categoryImages: "Kategoriebilder",
    subCategoryReview: "Unterkategorie überprüfen",
    subCategoryImages: "Unterkategorie Bilder",
    errorChangingVisibility: "Fehler beim Ändern der Sichtbarkeit.",
    successChangingVisibility: "Sichtbarkeit erfolgreich geändert.",
    successDeletingImage: "Bild erfolgreich gelöscht.",
    errorDeletingImage: "Fehler beim Löschen des Bildes.",
    categoryImage: "Kategoriebild",
    addSubCategoryImages: "Unterkategorie Bilder hinzufügen",
    categories: "Kategorien",
    categoriesAndImages: "Kategorien/Bilder",
    sendedCategoryImages: "Gesendete Kategoriebilder",
    uploadEditedImage: "Bearbeitetes Bild hochladen",
    downloadSendedImage: "Gesendetes Bild herunterladen",
    categoryTableTitle: {
      categoryId: "ID",
      categoryName: "Kategoriename",
      categoryDescription: "Beschreibung",
      categorySerial: "Seriell",
      language: "Sprache",
    },
    dealer: "Händler ( Mitarbeiter )",
    dealerTexts: {
      dealers: "Händler ( Mitarbeiter )",
      yes: "Ja",
      no: "Nein",
      addDealer: "Händler ( Mitarbeiter ) hinzufügen",
      deleteDealer: "Händler ( Mitarbeiter ) löschen",
      deleteDealerMessage: "Möchten Sie den Händler wirklich löschen?",
      deleteDealerSuccess: "Händler ( Mitarbeiter ) erfolgreich gelöscht.",
      deleteDealerError:
        "Beim Löschen des Händlers ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",

      search: "Suche",
      previous: "Vorherige",
      next: "Nächste",
      dealerAddress: "Adresse",
      dealerTableTitle: {
        dealerId: "ID",
        dealerAddress: "Adresse",
        dealerPhone: "Telefon",
        dealerName: "Händlername ( Mitarbeiter )",
      },
      dealerCity: "Stadt",
      dealerCountry: "Land",
      dealerEmail: "E-Mail",
      dealerName: "Händlername ( Mitarbeiter )",
      dealerPhone: "Telefon",
      dealerPostalCode: "Postleitzahl",
      dealerCountry: "Land",
      addZipCode: "Postleitzahl hinzufügen",
      dealerSave: "Speichern",
      dealerCancel: "Abbrechen",
      dealerState: "Zustand",
      dealerTitle: "Händler ( Mitarbeiter )",
      editDealer: "Händler ( Mitarbeiter ) bearbeiten",
      updateDealer: "Händler ( Mitarbeiter ) aktualisieren",
      dealerSaveError:
        "Beim Hinzufügen des Händlers ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      dealerUpdateError:
        "Beim Aktualisieren des Händlers ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      dealerSaveSuccess: "Händler erfolgreich hinzugefügt.",
      dealerUpdateSuccess: "Händler erfolgreich aktualisiert.",
    },
    analysis: "Analyse",
    payments: "Zahlungen",
    users: "Benutzer",
    saveStatus: {
      imageSaveError:
        "Beim Speichern des Bildes ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      userSaveError:
        "Beim Speichern des Benutzers ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      relationSaveError:
        "Beim Speichern der Beziehung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      userSaveSuccess: "Benutzer erfolgreich hinzugefügt.",
      userUpdateSuccess: "Benutzer erfolgreich aktualisiert.",
      deleteUserMessage: "Möchten Sie den Benutzer wirklich löschen?",
      deleteUserSuccess: "Benutzer erfolgreich gelöscht.",
      deleteUserError:
        "Beim Löschen des Benutzers ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
    },
    userTableTitle: {
      id: "ID",
      name: "Name",
      surname: "Nachname",
      email: "E-Mail",
      companyName: "Firmenname",
      companyNumber: "Firmennummer",
      role: "Rolle",
      freeTrial: "Kostenlose Testversion",
      userName: "Benutzername",
      userPassword: "Passwort",
      userImage: "Benutzerbild",
      userTaxNumber: "Steuernummer",
      userBackgrondImages: "Benutzerhintergrundbilder",
      userRegistered: "Registrierungsdatum",
      phone: "Telefon",
      editUser: "Benutzer bearbeiten",
      addUser: "Benutzer hinzufügen",
      updateUser: "Benutzer aktualisieren",
      deleteUser: "Benutzer löschen",
      userIBAN: "IBAN",
      userAccountNumber: "Bankkontonummer",
      userBankName: "Bankname",
      userBankAccountOwnerName: "Bankkontoinhabername",
      userBankAccountOwnerSurname: "Bankkontoinhabernachname",
      userZipCode: "Postleitzahl",
    },
    userModalTable: {
      userDetails: "Benutzerdetails",
      userInformation: "Benutzerinformation",
      userBackgrondImages: "Hintergrundbilder",
      id: "ID",
      name: "Name",
      surname: "Nachname",
      userName: "Benutzername",
      password: "Passwort",
      email: "E-Mail",
      phone: "Telefon",
      role: "Rolle",
      companyName: "Firmenname",
      freeTrial: "Kostenlose Testversion",
      freeTrialYes: "Ja",
      freeTrialNo: "Nein",
      registerDate: "Registrierungsdatum",
      userBankInfo: "Bankinformationen",
      userTaxNumber: "Steuernummer",
      userIBAN: "IBAN",
      userBankAccountOwner: "Bankkontoinhaber",
      userAccountNumber: "Bankkontonummer",
      userBankName: "Bankname",
      dealerInfo: "Händlerinformationen",
      dealerName: "Händlername",
      dealerAddress: "Adresse",
      dealerPhone: "Telefon",
      dealerPostalCode: "Postleitzahl",
    },

    paymentTableTitle: {
      paymentId: "ID",
      userId: "Benutzer ID",
      userName: "Benutzername",
      paymentAmount: "Zahlungsbetrag",
      paymentStatus: "Zahlungsstatus",
      paymentDate: "Zahlungsdatum",
      paymentDescription: "Zahlungsbeschreibung",
      description: "Beschreibung",
    },
    loginPage: {
      username: "Benutzername",
      password: "Passwort",
      login: "Anmeldung",
      loginSuccess: "Anmeldung erfolgreich",
      loginError: "Benutzername oder Passwort ist falsch",
      loginWarning: "Benutzername oder Passwort darf nicht leer sein",
      selectLanguage: "Sprache auswählen",
    },
    supportRequests: "Supportanfragen",
    sendedImages: "Gesendete Bilder",
    userBackgrondImages: "Benutzerhintergrundbilder",
    sendedProducts: "Gesendete Produkte",
    sendedProductsFromUsers: "Von Ben usern gesendete Produkte",
    Images: "Bilder",
    search: "Suche",
    previous: "Vorherige",
    next: "Nächste",
    selectLanguages: "Sprache auswählen",
    addBusiness: "Geschäft hinzufügen",
    editBusiness: "Geschäft bearbeiten",
    addProduct: "Produkt hinzufügen",
    editProduct: "Produkt bearbeiten",
    deleteProduct: "Produkt löschen",
    showProductImage: "Produktbild anzeigen",
    showImages: "Bilder anzeigen",
    noImageFound: "Kein Bild gefunden",
    productImageSize: "Produktbildgröße sollte 1000x1000 Pixel sein.",
    addImage: "Bild hinzufügen",
    productName: "Produktname",
    yes: "Ja",
    no: "Nein",
  },
  fr: {
    welcomeToDashboard: "Bienvenue sur le tableau de bord",
    homePage: "Page d'accueil",
    successMessages: {
      saveSuccess: "Enregistré avec succès.",
      updateSuccess: "Mis à jour avec succès.",
      deleteSuccess: "Supprimé avec succès.",
    },
    errorMessages: {
      saveError:
        "Une erreur s'est produite lors de l'enregistrement. Veuillez réessayer.",
      updateError:
        "Une erreur s'est produite lors de la mise à jour. Veuillez réessayer.",
      deleteError:
        "Une erreur s'est produite lors de la suppression. Veuillez réessayer.",
    },
    printerPage: "Page d'imprimante",
    pricePerMonth: "€ / Mensuel",
    printRequestTableTitle: {
      printRequestId: "ID",
      printImage: "Image",
      printType: "Type",
      printUser: "Utilisateur",
      printName: "Nom",
      printStatus: "Statut",
      printDate: "Date",
      printPrice: "Prix",
      printStatusCompleted: "Terminé",
      printStatusPending: "En attente",
      printRequestDate: "Date",
      printPaymentStatus: "Statut de paiement",
      printPaymentStatusPaid: "Payé",
      printPaymentStatusUnpaid: "Non payé",
      viewPrices: "Voir les prix",
      deletePrintRequest: "Supprimer la demande d'impression",
    },
    printPriceTableTitle: {
      printName: "Nom",
      printDescription: "Description",
      printPrice: "Prix",
      addPrice: "Ajouter un prix",
      editPrice: "Modifier le prix",
      deletePrice: "Supprimer le prix",
    },
    addPrintTable: {
      printNameDe: "Nom (Allemand)",
      printNameEn: "Nom (Anglais)",
      printNameTr: "Nom (Turc)",
      printNameFr: "Nom (Français)",
      printNameIt: "Nom (Italien)",
      printNameNl: "Nom (Néerlandais)",
      printDescription: "Description",
      printPrice: "Prix",
      printSave: "Enregistrer",
      printCancel: "Annuler",
    },
    packages: "Forfaits",
    addPackage: "Ajouter un forfait",
    savePackage: "Enregistrer le forfait",
    updatePackage: "Mettre à jour le forfait",
    NewlyAddedImages: "Images récemment ajoutées",
    editPackage: "Modifier le forfait",
    deletePackage: "Supprimer le forfait",
    packageSaveSuccess: "Forfait ajouté avec succès.",
    packageDeleteInfo:
      "La suppression du forfait supprimera toutes les images liées au forfait. Êtes-vous sûr de vouloir continuer ?",
    packageSaveError:
      "Une erreur s'est produite lors de l'ajout du forfait. Veuillez réessayer.",
    packageUpdateSuccess: "Forfait mis à jour avec succès.",
    packageType0: "Argent (Unique)",
    packageType1: "Or (Multiple)",
    packageType2: "Platine (Les deux)",
    packagesTableTitle: {
      packageId: "ID",
      packageName: "Nom",
      packageNameTr: "Nom (Turc)",
      packageNameDe: "Nom (Allemand)",
      packageNameEn: "Nom (Anglais)",
      packageNameFr: "Nom (Français)",
      packageNameIt: "Nom (Italien)",
      packageNameNl: "Nom (Néerlandais)",
      packageRegion: "Région",
      packagePrice: "Prix",
      packageDescription: "La description",
      package3Monthly: "3 Mensuel",
      package6Monthly: "6 Mensuel",
      packageYearly: "Annuel",
      package2Yearly: "2 Annuel",
      packageLanguage: "Langue du forfait",
      packageDetails: "Détails du forfait",
      packageInformation: "Information sur le forfait",
      editPackage: "Modifier le forfait",
    },
    menu: "Menu",
    products: "Produits",
    productDeleteInfo:
      "La suppression du produit supprimera toutes les images liées au produit. Êtes-vous sûr de vouloir continuer ?",
    productDelete: "Supprimer",
    productCancel: "Annuler",
    productDeleteSuccess: "Produit supprimé avec succès.",
    productDeleteError:
      "Une erreur s'est produite lors de la suppression du produit. Veuillez réessayer.",
    close: "Fermer",
    addBackgroundImages: {
      addImage: "Ajouter une image",
      addBackgroundImage: "Ajouter une image de fond",
      backgroundImageSize: "La taille de l'image de fond doit être de 1.414:1.",
      backgroundImageSave: "Sauvegarder",
      backgroundImageCancel: "Annuler",
      backgroundImagesSaveSuccess: "Image de fond ajoutée avec succès.",
      backgroundImageSaveError:
        "Une erreur s'est produite lors de l'ajout de l'image de fond. Veuillez réessayer.",
      selectBackgroundImage: "Sélectionner une image de fond",
      selectUserError: "Veuillez sélectionner un utilisateur",
      selectBackgroundImageError: "Veuillez sélectionner une image de fond",
      singleBackgroundImage: "Image de fond unique",
      multipleBackgroundImages: "Images de fond multiples",
      backgroundImages: "Images de fond",
      backgroundImage: "Image de fond",
      deleteImageWarning: "Êtes-vous sûr de vouloir supprimer cette image?",
      deleteBackgroundImageSuccess: "Image de fond supprimée avec succès.",
      deleteBackgroundImageError:
        "Une erreur s'est produite lors de la suppression de l'image.",
      yes: "Oui",
      no: "Non",
    },

    addProductItems: {
      addImage: "Ajouter une image",
      addProduct: "Ajouter un produit",
      productImageSize:
        "La taille de l'image du produit doit être de 1000x1000 pixels.",
      productName: "Nom du produit",
      category: "Catégorie",
      subCategory: "Sous-catégorie",
      productDescription: "Description du produit",
      productPrice: "Prix",
      productStock: "Stock",
      productSave: "Sauvegarder",
      productCancel: "Annuler",
    },
    addCategoryItems: {
      addImage: "Ajouter une image",
      addSubCategoryImages: "Ajouter des images de sous-catégorie",
      addCategory: "Ajouter une catégorie",
      categoryImageSize:
        "La taille de l'image de la catégorie doit être de 1000x1000 pixels.",
      categoryName: "Nom de la catégorie",
      category: "Catégorie",
      subCategory: "Sous-catégorie",
      categoryDescription: "Description de la catégorie",
      addSubCategory: "Ajouter une sous-catégorie",
      categoryLanguage: "Langue de la catégorie",
      categorySave: "Sauvegarder",
      categoryCancel: "Annuler",
      categoryNameTr: "Nom de la catégorie (Turc)",
      categoryNameDe: "Nom de la catégorie (Allemand)",
      categoryNameEn: "Nom de la catégorie (Anglais)",
      categoryNameFr: "Nom de la catégorie (Français)",
      categoryNameIt: "Nom de la catégorie (Italien)",
      categoryNameNl: "Nom de la catégorie (Néerlandais)",
      categorySaveError:
        "Une erreur s'est produite lors de l'ajout de la catégorie. Veuillez réessayer.",
      categoryUpdateError:
        "Une erreur s'est produite lors de la mise à jour de la catégorie. Veuillez réessayer.",
      categorySaveSuccess: "Catégorie ajoutée avec succès.",
      categoryUpdateSuccess: "Catégorie mise à jour avec succès.",
      subCategoryDeleteInfo:
        "La suppression de la sous-catégorie supprimera toutes les images liées à la catégorie. Êtes-vous sûr de vouloir continuer ?",
      categoryDeleteInfo:
        "La suppression de la catégorie supprimera toutes les sous-catégories et images liées à la catégorie. Êtes-vous sûr de vouloir continuer ?",
      deleteSubCategoryConfirm: "Êtes-vous sûr ?",
      deleteSubCategory: "Supprimer",
      deleteSubCategoryCancel: "Annuler",

      categoryDeleteError:
        "Une erreur s'est produite lors de la suppression de la catégorie. Veuillez réessayer.",
      categoryDeleteSuccess: "Catégorie supprimée avec succès.",
      fileUploadError:
        "Une erreur s'est produite lors du téléchargement du fichier. Veuillez réessayer.",
      fileUploadSuccess: "Fichier téléchargé avec succès.",
      categoryImageRelationError:
        "Échec de la relation d'image de catégorie. Veuillez réessayer.",
      categoryImageRelationSuccess: "Relation d'image de catégorie réussie.",
      fileExtensionError: "L'extension de fichier doit être jpg, jpeg, png.",
      fileAspectRatioError:
        "Le rapport hauteur/largeur du fichier doit être de 1:1.",
    },
    showSubCategories: "Afficher les sous-catégories",
    showCategories: "Afficher les catégories",
    productsTableTitle: {
      productId: "ID",
      productName: "Nom",
      productUser: "Utilisateur",
      productDescription: "Description",
      productImage: "Image",
      category: "Catégorie",
      subCategory: "Sous-catégorie",
      productStatus: "Statut",
    },
    pending: "En attente",
    approved: "Approuvé",
    rejected: "Rejeté",
    Areyousurewanttodeletethisimage:
      "Voulez-vous vraiment supprimer cette image ?",
    category: "Catégorie",
    subCategory: "Sous-catégorie",
    subCategories: "Sous-catégories",
    addCategory: "Ajouter une catégorie",
    addSubCategory: "Ajouter une sous-catégorie",
    editSubCategory: "Modifier la sous-catégorie",
    editCategory: "Modifier la catégorie",
    deleteCategory: "Supprimer la catégorie",
    reviewCategory: "Examiner la catégorie",
    categoryImages: "Images de catégorie",
    categoryImage: "Image de catégorie",
    subCategoryReview: "Examiner la sous-catégorie",
    subCategoryImages: "Images de sous-catégorie",
    errorChangingVisibility:
      "Une erreur s'est produite lors du changement de visibilité.",
    successChangingVisibility: "Visibilité modifiée avec succès.",
    successDeletingImage: "Image supprimée avec succès.",
    errorDeletingImage: "Erreur lors de la suppression de l'image.",
    addSubCategoryImages: "Ajouter des images de sous-catégorie",
    categories: "Catégories",
    categoriesAndImages: "Catégories/Images",
    sendedCategoryImages: "Images de catégorie envoyées",
    uploadEditedImage: "Télécharger l'image modifiée",
    downloadSendedImage: "Télécharger l'image envoyée",
    categoryTableTitle: {
      categoryId: "ID",
      categoryName: "Nom de la catégorie",
      categoryDescription: "La description",
      categorySerial: "Série",
      language: "Langue",
    },
    dealer: "Concessionnaire",
    dealerTexts: {
      dealers: "Concessionnaires",
      yes: "Oui",
      no: "Non",
      addDealer: "Ajouter un concessionnaire",
      deleteDealer: "Supprimer le concessionnaire",
      deleteDealerMessage:
        "Êtes-vous sûr de vouloir supprimer le concessionnaire ?",
      deleteDealerSuccess: "Concessionnaire supprimé avec succès.",
      deleteDealerError:
        "Une erreur s'est produite lors de la suppression du concessionnaire. Veuillez réessayer.",
      search: "Chercher",
      previous: "Précédent",
      next: "Suivant",
      dealerAddress: "Adresse",
      dealerTableTitle: {
        dealerId: "ID",
        dealerAddress: "Adresse",
        dealerPhone: "Téléphone",
        dealerName: "Nom du concessionnaire",
      },
      dealerCity: "Ville",
      dealerCountry: "Pays",
      dealerEmail: "E-mail",
      dealerName: "Nom du concessionnaire",
      dealerPhone: "Téléphone",
      dealerPostalCode: "Code postal",
      dealerCountry: "Pays",
      addZipCode: "Ajouter un code postal",
      dealerSave: "Sauvegarder",
      dealerCancel: "Annuler",
      dealerState: "État",
      dealerTitle: "Concessionnaires",
      editDealer: "Modifier le concessionnaire",
      updateDealer: "Mettre à jour le concessionnaire",
      dealerSaveError:
        "Une erreur s'est produite lors de l'ajout du concessionnaire. Veuillez réessayer.",
      dealerUpdateError:
        "Une erreur s'est produite lors de la mise à jour du concessionnaire. Veuillez réessayer.",
      dealerSaveSuccess: "Concessionnaire ajouté avec succès.",
      dealerUpdateSuccess: "Concessionnaire mis à jour avec succès.",
    },
    analysis: "Analyse",
    payments: "Paiements",
    users: "Utilisateurs",
    saveStatus: {
      imageSaveError:
        "Une erreur s'est produite lors de l'enregistrement de l'image. Veuillez réessayer.",
      userSaveError:
        "Une erreur s'est produite lors de l'enregistrement de l'utilisateur. Veuillez réessayer.",
      relationSaveError:
        "Une erreur s'est produite lors de l'enregistrement de la relation. Veuillez réessayer.",
      userSaveSuccess: "Utilisateur ajouté avec succès.",
      userUpdateSuccess: "Utilisateur mis à jour avec succès.",
      deleteUserMessage: "Voulez-vous vraiment supprimer l'utilisateur ?",
      deleteUserSuccess: "Utilisateur supprimé avec succès.",
      deleteUserError:
        "Une erreur s'est produite lors de la suppression de l'utilisateur. Veuillez réessayer.",
    },
    userTableTitle: {
      id: "ID",
      name: "Nom",
      surname: "Nom de famille",
      email: "E-mail",
      companyName: "Nom de l'entreprise",
      companyNumber: "Numéro d'entreprise",
      role: "Rôle",
      freeTrial: "Essai gratuit",
      userName: "Nom d'utilisateur",
      userPassword: "Mot de passe",
      userImage: "Image d'utilisateur",
      userTaxNumber: "Numéro de TVA",
      userBackgrondImages: "Images d'arrière-plan utilisateur",
      userRegistered: "Date d'inscription",
      phone: "Téléphone",
      editUser: "Modifier l'utilisateur",
      addUser: "Ajouter un utilisateur",
      updateUser: "Mettre à jour l'utilisateur",
      deleteUser: "Supprimer l'utilisateur",
      userIBAN: "IBAN",
      userAccountNumber: "Numéro de compte bancaire",
      userBankName: "Nom de la banque",
      userBankAccountOwnerName: "Nom du propriétaire du compte bancaire",
      userBankAccountOwnerSurname:
        "Nom de famille du propriétaire du compte bancaire",
      userZipCode: "Code postal",
    },
    userModalTable: {
      userDetails: "Détails de l'utilisateur",
      userInformation: "Informations de l'utilisateur",
      userBackgrondImages: "Images d'arrière-plan",
      id: "ID",
      name: "Nom",
      surname: "Nom de famille",
      userName: "Nom d'utilisateur",
      password: "Mot de passe",
      email: "E-mail",
      phone: "Téléphone",
      role: "Rôle",
      companyName: "Nom de l'entreprise",
      freeTrial: "Essai gratuit",
      freeTrialYes: "Oui",
      freeTrialNo: "Non",
      registerDate: "Date d'inscription",
      userBankInfo: "Informations bancaires",
      userTaxNumber: "Numéro de TVA",
      userIBAN: "IBAN",
      userBankAccountOwner: "Propriétaire du compte bancaire",
      userAccountNumber: "Numéro de compte bancaire",
      userBankName: "Nom de la banque",
      dealerInfo: "Informations sur le concessionnaire",
      dealerName: "Nom du concessionnaire",
      dealerAddress: "Adresse",
      dealerPhone: "Téléphone",
      dealerPostalCode: "Code postal",
    },
    paymentTableTitle: {
      paymentId: "ID",
      userId: "ID de l'utilisateur",
      userName: "Nom d'utilisateur",
      paymentAmount: "Montant du paiement",
      paymentStatus: "Statut du paiement",
      paymentDate: "Date de paiement",
      paymentDescription: "Description du paiement",
      description: "Description",
    },
    loginPage: {
      username: "Nom d'utilisateur",
      password: "Mot de passe",
      login: "S'identifier",
      loginSuccess: "Connexion réussie",
      loginError: "Nom d'utilisateur ou mot de passe incorrect",
      loginWarning:
        "Le nom d'utilisateur ou le mot de passe ne peut pas être vide",
      selectLanguage: "Sélectionner la langue",
    },
    supportRequests: "Demandes de support",
    sendedImages: "Images envoyées",
    userBackgrondImages: "Images d'arrière-plan utilisateur",
    sendedProducts: "Produits envoyés",
    sendedProductsFromUsers: "Produits envoyés par les utilisateurs",
    Images: "Images",
    search: "Chercher",
    previous: "Précédent",
    next: "Suivant",
    selectLanguages: "Sélectionner la langue",
    addBusiness: "Ajouter une entreprise",
    editBusiness: "Modifier l'entreprise",
    addProduct: "Ajouter un produit",
    editProduct: "Modifier le produit",
    deleteProduct: "Supprimer le produit",
    showProductImage: "Afficher l'image du produit",
    showImages: "Afficher les images",
    noImageFound: "Aucune image trouvée",
    productImageSize:
      "La taille de l'image du produit doit être de 1000x1000 pixels.",
    addImage: "Ajouter une image",
    productName: "Nom du produit",
    yes: "Oui",
    no: "Non",
  },
  it: {
    welcomeToDashboard: "Benvenuto nella dashboard",
    homePage: "Pagina iniziale",
    successMessages: {
      saveSuccess: "Salvato con successo.",
      updateSuccess: "Aggiornato con successo.",
      deleteSuccess: "Eliminato con successo.",
    },
    errorMessages: {
      saveError: "Si è verificato un errore durante il salvataggio. Riprova.",
      updateError:
        "Si è verificato un errore durante l'aggiornamento. Riprova.",
      deleteError: "Si è verificato un errore durante l'eliminazione. Riprova.",
    },
    printerPage: "Pagina della stampante",
    pricePerMonth: "€ / Mensile",
    printRequestTableTitle: {
      printRequestId: "ID",
      printImage: "Immagine",
      printType: "Tipo",
      printUser: "Utente",
      printName: "Nome",
      printStatus: "Stato",
      printDate: "Data",
      printPrice: "Prezzo",
      printStatusCompleted: "Completato",
      printStatusPending: "In attesa",
      printRequestDate: "Data",
      printPaymentStatus: "Stato del pagamento",
      printPaymentStatusPaid: "Pagato",
      printPaymentStatusUnpaid: "Non pagato",
      viewPrices: "Visualizza i prezzi",
      deletePrintRequest: "Elimina richiesta di stampa",
    },
    printPriceTableTitle: {
      printName: "Nome",
      printDescription: "Descrizione",
      printPrice: "Prezzo",
      addPrice: "Aggiungi prezzo",
      editPrice: "Modifica prezzo",
      deletePrice: "Elimina prezzo",
    },
    addPrintTable: {
      printNameDe: "Nome (Tedesco)",
      printNameEn: "Nome (Inglese)",
      printNameTr: "Nome (Turco)",
      printNameFr: "Nome (Francese)",
      printNameIt: "Nome (Italiano)",
      printNameNl: "Nome (Olandese)",
      printDescription: "Descrizione",
      printPrice: "Prezzo",
      printSave: "Salva",
      printCancel: "Annulla",
    },
    packages: "Pacchetti",
    addPackage: "Aggiungi pacchetto",
    savePackage: "Salva pacchetto",
    updatePackage: "Aggiorna pacchetto",
    NewlyAddedImages: "Immagini appena aggiunte",
    editPackage: "Modifica pacchetto",
    deletePackage: "Elimina pacchetto",
    packageSaveSuccess: "Pacchetto aggiunto con successo.",
    packageDeleteInfo:
      "Eliminando il pacchetto verranno eliminati tutti gli immagini correlate al pacchetto. Sei sicuro di voler continuare?",
    packageSaveError:
      "Si è verificato un errore durante l'aggiunta del pacchetto. Riprova.",
    packageUpdateSuccess: "Pacchetto aggiornato con successo.",
    packageType0: "Argento (Singolo)",
    packageType1: "Oro (Multiplo)",
    packageType2: "Platino (Entrambi)",
    packagesTableTitle: {
      packageId: "ID",
      packageName: "Nome",
      packageNameTr: "Nome (Turco)",
      packageNameDe: "Nome (Tedesco)",
      packageNameEn: "Nome (Inglese)",
      packageNameFr: "Nome (Francese)",
      packageNameIt: "Nome (Italiano)",
      packageNameNl: "Nome (Olandese)",
      packageRegion: "Regione",
      packagePrice: "Prezzo",
      packageDescription: "Descrizione",
      package3Monthly: "3 Mensile",
      package6Monthly: "6 Mensile",
      packageYearly: "Annuale",
      package2Yearly: "Biennale",
      packageLanguage: "Lingua del pacchetto",
      packageDetails: "Dettagli del pacchetto",
      packageInformation: "Informazioni sul pacchetto",
      editPackage: "Modifica pacchetto",
    },
    menu: "Menu",
    products: "Prodotti",
    productDeleteInfo:
      "Eliminando il prodotto verranno eliminati tutti gli immagini correlate al prodotto. Sei sicuro di voler continuare?",
    productDelete: "Elimina",
    productCancel: "Annulla",
    productDeleteSuccess: "Prodotto eliminato con successo.",
    close: "Chiudi",
    addBackgroundImages: {
      addImage: "Aggiungi immagine",
      addBackgroundImage: "Aggiungi immagine di sfondo",
      backgroundImageSize:
        "La dimensione dell'immagine di sfondo deve essere 1.414:1.",
      backgroundImageSave: "Salva",
      backgroundImageCancel: "Annulla",
      backgroundImagesSaveSuccess: "Immagine di sfondo aggiunta con successo.",
      backgroundImageSaveError:
        "Si è verificato un errore durante l'aggiunta dell'immagine di sfondo. Riprova.",
      selectBackgroundImage: "Seleziona immagine di sfondo",
      selectUserError: "Seleziona un utente",
      selectBackgroundImageError: "Seleziona un'immagine di sfondo",
      singleBackgroundImage: "Immagine di sfondo singola",
      multipleBackgroundImages: "Immagini di sfondo multiple",
      backgroundImages: "Immagini di sfondo",
      backgroundImage: "Immagine di sfondo",
      deleteImageWarning: "Sei sicuro di voler eliminare questa immagine?",
      deleteBackgroundImageSuccess:
        "Immagine di sfondo eliminata con successo.",
      deleteBackgroundImageError:
        "Errore durante l'eliminazione dell'immagine.",
      yes: "Sì",
      no: "No",
    },

    addProductItems: {
      addImage: "Aggiungi immagine",
      addProduct: "Aggiungi prodotto",
      productImageSize:
        "Le dimensioni dell'immagine del prodotto devono essere 1000x1000 pixel.",
      productName: "Nome del prodotto",
      category: "Categoria",
      subCategory: "Sottocategoria",
      productDescription: "Descrizione del prodotto",
      productPrice: "Prezzo",
      productStock: "Scorta",
      productSave: "Salva",
      productCancel: "Annulla",
    },
    addCategoryItems: {
      addImage: "Aggiungi immagine",
      addSubCategoryImages: "Aggiungi immagini della sottocategoria",
      editCategory: "Modifica categoria",

      reviewCategory: "Rivedi categoria",
      categoryImageSize:
        "Le dimensioni dell'immagine della categoria devono essere 1000x1000 pixel.",
      categoryName: "Nome della categoria",
      category: "Categoria",
      subCategory: "Sottocategoria",
      categoryDescription: "Descrizione della categoria",
      addSubCategory: "Aggiungi sottocategoria",
      categoryLanguage: "Lingua della categoria",
      categorySave: "Salva",
      categoryCancel: "Annulla",
      categoryNameTr: "Nome della categoria (Turco)",
      categoryNameDe: "Nome della categoria (Tedesco)",
      categoryNameEn: "Nome della categoria (Inglese)",
      categoryNameFr: "Nome della categoria (Francese)",
      categoryNameIt: "Nome della categoria (Italiano)",
      categoryNameNl: "Nome della categoria (Olandese)",
      categorySaveError:
        "Si è verificato un errore durante l'aggiunta della categoria. Riprova.",
      categoryUpdateError:
        "Si è verificato un errore durante l'aggiornamento della categoria. Riprova.",
      categorySaveSuccess: "Categoria aggiunta con successo.",
      categoryUpdateSuccess: "Categoria aggiornata con successo.",
      subCategoryDeleteInfo:
        "L'eliminazione della sottocategoria elimina tutte le immagini correlate alla categoria. Vuoi continuare?",
      categoryDeleteInfo:
        "L'eliminazione della categoria elimina tutte le sottocategorie e le immagini correlate alla categoria. Vuoi continuare?",
      deleteSubCategoryConfirm: "Sei sicuro?",
      deleteSubCategory: "Elimina",
      deleteSubCategoryCancel: "Annulla",
      categoryDeleteError:
        "Si è verificato un errore durante l'eliminazione della categoria. Riprova.",
      categoryDeleteSuccess: "Categoria eliminata con successo.",
      fileUploadError:
        "Si è verificato un errore durante il caricamento del file. Riprova.",
      fileUploadSuccess: "File caricato con successo.",
      categoryImageRelationError:
        "Relazione immagine categoria fallita. Riprova.",
      categoryImageRelationSuccess: "Relazione immagine categoria riuscita.",
      fileExtensionError: "L'estensione del file deve essere jpg, jpeg, png.",
      fileAspectRatioError:
        "Il rapporto larghezza/altezza del file deve essere 1:1.",
    },
    showSubCategories: "Mostra sottocategorie",
    showCategories: "Mostra categorie",
    productsTableTitle: {
      productId: "ID",
      productName: "Nome",
      productUser: "Utente",
      productDescription: "Descrizione",
      productImage: "Immagine",
      category: "Categoria",
      subCategory: "Sottocategoria",
      productStatus: "Stato",
    },
    pending: "In attesa",
    approved: "Approvato",
    rejected: "Rifiutato",
    Areyousurewanttodeletethisimage:
      "Sei sicuro di voler eliminare questa immagine?",
    category: "Categoria",
    subCategory: "Sottocategoria",
    subCategories: "Sottocategorie",
    addCategory: "Aggiungi categoria",
    addSubCategory: "Aggiungi sottocategoria",
    editSubCategory: "Modifica sottocategoria",
    editCategory: "Modifica categoria",
    deleteCategory: "Elimina categoria",
    reviewCategory: "Rivedi categoria",
    categoryImages: "Immagini della categoria",
    categoryImage: "Immagine della categoria",
    subCategoryReview: "Rivedi sottocategoria",
    subCategoryImages: "Immagini della sottocategoria",
    errorChangingVisibility: "Errore nel cambiare la visibilità.",
    successChangingVisibility: "Visibilità cambiata con successo.",
    successDeletingImage: "Immagine eliminata con successo.",
    errorDeletingImage: "Errore durante l'eliminazione dell'immagine.",
    addSubCategoryImages: "Aggiungi immagini della sottocategoria",
    categories: "Categorie",
    categoriesAndImages: "Categorie/Immagini",
    sendedCategoryImages: "Immagini della categoria inviate",
    uploadEditedImage: "Carica immagine modificata",
    downloadSendedImage: "Scarica immagine inviata",

    categoryTableTitle: {
      categoryId: "ID",
      categoryName: "Nome Categoria",
      categoryDescription: "Descrizione",
      categorySerial: "Serie",
      language: "Lingua",
    },
    dealer: "Concessionario",
    dealerTexts: {
      dealers: "Concessionari",
      yes: "Sì",
      no: "No",
      addDealer: "Aggiungi concessionario",
      deleteDealer: "Elimina concessionario",
      deleteDealerMessage: "Sei sicuro di voler eliminare il concessionario?",
      deleteDealerSuccess: "Concessionario eliminato con successo.",
      deleteDealerError:
        "Si è verificato un errore durante l'eliminazione del concessionario. Riprova.",
      search: "Ricerca",
      previous: "Precedente",
      next: "Successivo",
      dealerAddress: "Indirizzo",
      dealerTableTitle: {
        dealerId: "ID",
        dealerAddress: "Indirizzo",
        dealerPhone: "Telefono",
        dealerName: "Nome Concessionario",
      },
      dealerCity: "Città",
      dealerCountry: "Paese",
      dealerEmail: "E-mail",
      dealerName: "Nome Concessionario",
      dealerPhone: "Telefono",
      dealerPostalCode: "Codice Postale",
      dealerCountry: "Paese",
      addZipCode: "Aggiungi CAP",
      dealerSave: "Salva",
      dealerCancel: "Annulla",
      dealerState: "Stato",
      dealerTitle: "Concessionari",
      editDealer: "Modifica concessionario",
      updateDealer: "Aggiorna concessionario",
      dealerSaveError:
        "Si è verificato un errore durante l'aggiunta del concessionario. Riprova.",
      dealerUpdateError:
        "Si è verificato un errore durante l'aggiornamento del concessionario. Riprova.",
      dealerSaveSuccess: "Concessionario aggiunto con successo.",
      dealerUpdateSuccess: "Concessionario aggiornato con successo.",
    },
    analysis: "Analisi",
    payments: "Pagamenti",
    users: "Utenti",
    saveStatus: {
      imageSaveError: "Errore durante il salvataggio dell'immagine. Riprova.",
      userSaveError: "Errore durante il salvataggio dell'utente. Riprova.",
      relationSaveError:
        "Errore durante il salvataggio della relazione. Riprova.",
      userSaveSuccess: "Utente aggiunto con successo.",
      userUpdateSuccess: "Utente aggiornato con successo.",
      deleteUserMessage: "Sei sicuro di voler eliminare l'utente?",
      deleteUserSuccess: "Utente eliminato con successo.",
      deleteUserError: "Errore durante l'eliminazione dell'utente. Riprova.",
    },
    userTableTitle: {
      id: "ID",
      name: "Nome",
      surname: "Cognome",
      email: "E-mail",
      companyName: "Nome Azienda",
      companyNumber: "Numero Azienda",
      role: "Ruolo",
      freeTrial: "Prova Gratuita",
      userName: "Nome Utente",
      userPassword: "Password",
      userImage: "Immagine Utente",
      userTaxNumber: "Partita IVA",
      userBackgrondImages: "Immagini di sfondo utente",
      userRegistered: "Data di Registrazione",
      phone: "Telefono",
      editUser: "Modifica Utente",
      addUser: "Aggiungi Utente",
      updateUser: "Aggiorna Utente",
      deleteUser: "Elimina Utente",
      userIBAN: "IBAN",
      userAccountNumber: "Numero Conto Bancario",
      userBankName: "Nome Banca",
      userBankAccountOwnerName: "Nome Proprietario Conto Bancario",
      userBankAccountOwnerSurname: "Cognome Proprietario Conto Bancario",
      userZipCode: "Codice Postale",
    },
    userModalTable: {
      userDetails: "Dettagli Utente",
      userInformation: "Informazioni Utente",
      userBackgrondImages: "Immagini di sfondo",
      id: "ID",
      name: "Nome",
      surname: "Cognome",
      userName: "Nome Utente",
      password: "Password",
      email: "E-mail",
      phone: "Telefono",
      role: "Ruolo",
      companyName: "Nome Azienda",
      freeTrial: "Prova Gratuita",
      freeTrialYes: "Sì",
      freeTrialNo: "No",
      registerDate: "Data di Registrazione",
      userBankInfo: "Informazioni Bancarie",
      userTaxNumber: "Partita IVA",
      userIBAN: "IBAN",
      userBankAccountOwner: "Proprietario Conto Bancario",
      userAccountNumber: "Numero Conto Bancario",
      userBankName: "Nome Banca",
      dealerInfo: "Informazioni Concessionario",
      dealerName: "Nome Concessionario",
      dealerAddress: "Indirizzo",
      dealerPhone: "Telefono",
      dealerPostalCode: "Codice Postale",
    },
    paymentTableTitle: {
      paymentId: "ID",
      userId: "ID Utente",
      userName: "Nome Utente",
      paymentAmount: "Importo Pagamento",
      paymentStatus: "Stato Pagamento",
      paymentDate: "Data Pagamento",
      paymentDescription: "Descrizione Pagamento",
      description: "Descrizione",
    },
    loginPage: {
      username: "Nome Utente",
      password: "Password",
      login: "Accedi",
      loginSuccess: "Accesso riuscito",
      loginError: "Nome utente o password errati",
      loginWarning: "Nome utente o password non possono essere vuoti",
      selectLanguage: "Seleziona lingua",
    },
    supportRequests: "Richieste di supporto",
    sendedImages: "Immagini inviate",
    userBackgrondImages: "Immagini di sfondo utente",
    sendedProducts: "Prodotti inviati",
    sendedProductsFromUsers: "Prodotti inviati dagli utenti",
    Images: "Immagini",
    search: "Ricerca",
    previous: "Precedente",
    next: "Successivo",
    selectLanguages: "Seleziona lingua",
    addBusiness: "Aggiungi attività",
    editBusiness: "Modifica attività",
    addProduct: "Aggiungi prodotto",
    editProduct: "Modifica prodotto",
    deleteProduct: "Elimina prodotto",
    showProductImage: "Mostra immagine del prodotto",
    showImages: "Mostra immagini",
    noImageFound: "Nessuna immagine trovata",
    productImageSize:
      "Le dimensioni dell'immagine del prodotto devono essere 1000x1000 pixel.",
    addImage: "Aggiungi immagine",
    productName: "Nome del prodotto",
    yes: "Sì",
    no: "No",
  },
  nl: {
    welcomeToDashboard: "Welkom bij Dashboard",
    homePage: "Homepagina",
    successMessages: {
      saveSuccess: "Succesvol opgeslagen.",
      updateSuccess: "Succesvol bijgewerkt.",
      deleteSuccess: "Succesvol verwijderd.",
    },
    errorMessages: {
      saveError:
        "Er is een fout opgetreden bij het opslaan. Probeer het opnieuw.",
      updateError:
        "Er is een fout opgetreden bij het bijwerken. Probeer het opnieuw.",
      deleteError:
        "Er is een fout opgetreden bij het verwijderen. Probeer het opnieuw.",
    },
    printerPage: "Printerpagina",
    pricePerMonth: "€ / Maandelijks",
    printRequestTableTitle: {
      printRequestId: "ID",
      printImage: "Afbeelding",
      printType: "Type",
      printUser: "Gebruiker",
      printName: "Naam",
      printStatus: "Status",
      printDate: "Datum",
      printPrice: "Prijs",
      printStatusCompleted: "Voltooid",
      printStatusPending: "In afwachting",
      printRequestDate: "Datum",
      printPaymentStatus: "Betalingsstatus",
      printPaymentStatusPaid: "Beta",
      printPaymentStatusUnpaid: "Onbetaald",
      viewPrices: "Prijzen bekijken",
      deletePrintRequest: "Verwijder printverzoek",
    },
    printPriceTableTitle: {
      printName: "Naam",
      printDescription: "Beschrijving",
      printPrice: "Prijs",
      addPrice: "Prijs toevoegen",
      editPrice: "Prijs bewerken",
      deletePrice: "Prijs verwijderen",
    },
    addPrintTable: {
      printNameDe: "Naam (Duits)",
      printNameEn: "Naam (Engels)",
      printNameTr: "Naam (Turks)",
      printNameFr: "Naam (Frans)",
      printNameIt: "Naam (Italiaans)",
      printNameNl: "Naam (Nederlands)",
      printDescription: "Beschrijving",
      printPrice: "Prijs",
      printSave: "Opslaan",
      printCancel: "Annuleren",
    },
    packages: "Pakketten",
    addPackage: "Pakket toevoegen",
    savePackage: "Pakket opslaan",
    updatePackage: "Pakket bijwerken",
    NewlyAddedImages: "Nieuw toegevoegde afbeeldingen",
    editPackage: "Pakket bewerken",
    deletePackage: "Pakket verwijderen",
    packageSaveSuccess: "Pakket succesvol toegevoegd.",
    packageUpdateSuccess: "Pakket succesvol bijgewerkt.",
    packageDeleteInfo:
      "Het verwijderen van het pakket verwijdert alle afbeeldingen die aan het pakket zijn gekoppeld. Weet u zeker dat u wilt doorgaan?",
    packageSaveError:
      "Er is een fout opgetreden bij het toevoegen van het pakket. Probeer het opnieuw.",
    packageType0: "Zilver (Enkel)",
    packageType1: "Goud (Meervoudig)",
    packageType2: "Platina (Beide)",
    packagesTableTitle: {
      packageId: "ID",
      packageName: "Naam",
      packageNameTr: "Naam (Turks)",
      packageNameDe: "Naam (Duits)",
      packageNameEn: "Naam (Engels)",
      packageNameFr: "Naam (Frans)",
      packageNameIt: "Naam (Italiaans)",
      packageNameNl: "Naam (Nederlands)",
      packageRegion: "Regio",
      packagePrice: "Prijs",
      packageDescription: "Beschrijving",
      package3Monthly: "3 Maandelijks",
      package6Monthly: "6 Maandelijks",
      packageYearly: "Jaarlijks",
      package2Yearly: "2 Jaarlijks",
      packageLanguage: "Pakket talen",
      packageDetails: "Pakketdetails",
      packageInformation: "Pakketinformatie",
      editPackage: "Pakket bewerken",
    },
    menu: "Menu",
    products: "Producten",
    productDeleteInfo:
      "Het verwijderen van het product verwijdert alle afbeeldingen die aan het product zijn gekoppeld. Weet u zeker dat u wilt doorgaan?",
    productDelete: "Verwijderen",
    productCancel: "Annuleren",
    productDeleteSuccess: "Product succesvol verwijderd.",
    productDeleteError:
      "Er is een fout opgetreden bij het verwijderen van het product. Probeer het opnieuw.",
    close: "Sluiten",
    addBackgroundImages: {
      addImage: "Afbeelding toevoegen",
      addBackgroundImage: "Achtergrondafbeelding toevoegen",
      backgroundImageSize:
        "De achtergrondafbeelding moet een aspectverhouding van 1.414:1 hebben.",
      backgroundImageSave: "Opslaan",
      backgroundImageCancel: "Annuleren",
      backgroundImagesSaveSuccess:
        "Achtergrondafbeelding succesvol toegevoegd.",

      backgroundImageSaveError:
        "Er is een fout opgetreden bij het toevoegen van de achtergrondafbeelding. Probeer het opnieuw.",
      selectBackgroundImage: "Achtergrondafbeelding selecteren",
      selectUserError: "Selecteer een gebruiker",
      selectBackgroundImageError: "Selecteer een achtergrondafbeelding",
      singleBackgroundImage: "Enkele achtergrondafbeelding",
      multipleBackgroundImages: "Meerdere achtergrondafbeeldingen",
      backgroundImages: "Achtergrondafbeeldingen",
      backgroundImage: "Achtergrondafbeelding",
      deleteImageWarning:
        "Weet u zeker dat u deze afbeelding wilt verwijderen?",
      deleteBackgroundImageSuccess:
        "Achtergrondafbeelding succesvol verwijderd.",
      deleteBackgroundImageError: "Fout bij het verwijderen van de afbeelding.",
      yes: "Ja",
      no: "Nee",
    },

    addProductItems: {
      addImage: "Afbeelding toevoegen",
      addProduct: "Product toevoegen",
      productImageSize: "De productafbeelding moet 1000x1000 pixels zijn.",
      productName: "Productnaam",
      category: "Categorie",
      subCategory: "Subcategorie",
      productDescription: "Productbeschrijving",
      productPrice: "Prijs",
      productStock: "Voorraad",
      productSave: "Opslaan",
      productCancel: "Annuleren",
    },
    addCategoryItems: {
      addImage: "Afbeelding toevoegen",
      addSubCategoryImages: "Subcategorie afbeeldingen toevoegen",
      addCategory: "Categorie toevoegen",
      editCategory: "Categorie bewerken",
      reviewCategory: "Categorie bekijken",
      categoryImageSize: "De categorieafbeelding moet 1000x1000 pixels zijn.",
      categoryName: "Categorienaam",
      category: "Categorie",
      subCategory: "Subcategorie",
      categoryDescription: "Categoriebeschrijving",
      addSubCategory: "Subcategorie toevoegen",
      categoryLanguage: "Categorietalen",
      categorySave: "Opslaan",
      categoryCancel: "Annuleren",
      categoryNameTr: "Categorienaam (Turks)",
      categoryNameDe: "Categorienaam (Duits)",
      categoryNameEn: "Categorienaam (Engels)",
      categoryNameFr: "Categorienaam (Frans)",
      categoryNameIt: "Categorienaam (Italiaans)",
      categoryNameNl: "Categorienaam (Nederlands)",
      categorySaveError:
        "Er is een fout opgetreden bij het toevoegen van de categorie. Probeer het opnieuw.",
      categoryUpdateError:
        "Er is een fout opgetreden bij het bijwerken van de categorie. Probeer het opnieuw.",
      categorySaveSuccess: "Categorie succesvol toegevoegd.",
      categoryUpdateSuccess: "Categorie succesvol bijgewerkt.",
      subCategoryDeleteInfo:
        "Het verwijderen van de subcategorie verwijdert alle afbeeldingen die aan de categorie zijn gekoppeld. Weet u zeker dat u wilt doorgaan?",
      categoryDeleteInfo:
        "Het verwijderen van de categorie verwijdert alle subcategorieën en afbeeldingen die aan de categorie zijn gekoppeld. Weet u zeker dat u wilt doorgaan?",
      deleteSubCategoryConfirm: "Weet je het zeker?",
      deleteSubCategory: "Verwijderen",
      deleteSubCategoryCancel: "Annuleren",
      categoryDeleteError:
        "Er is een fout opgetreden bij het verwijderen van de categorie. Probeer het opnieuw.",
      categoryDeleteSuccess: "Categorie succesvol verwijderd.",
      fileUploadError:
        "Er is een fout opgetreden bij het uploaden van het bestand. Probeer het opnieuw.",
      fileUploadSuccess: "Bestand succesvol geüpload.",
      categoryImageRelationError:
        "Categorie afbeeldingsrelatie mislukt. Probeer het opnieuw.",
      categoryImageRelationSuccess: "Categorie afbeeldingsrelatie succesvol.",
      fileExtensionError: "Alleen .jpg, .jpeg, .png-bestanden zijn toegestaan.",
      fileAspectRatioError:
        "De afbeelding moet een aspectverhouding van 1:1 hebben.",
    },
    showSubCategories: "Subcategorieën weergeven",
    showCategories: "Categorieën weergeven",
    productsTableTitle: {
      productId: "ID",
      productName: "Naam",
      productUser: "Gebruiker",
      productDescription: "Beschrijving",
      productImage: "Afbeelding",
      category: "Categorie",
      subCategory: "Subcategorie",
      productStatus: "Status",
    },
    pending: "In afwachting",
    approved: "Goedgekeurd",
    rejected: "Afgewezen",
    Areyousurewanttodeletethisimage:
      "Weet u zeker dat u deze afbeelding wilt verwijderen?",
    category: "Categorie",
    subCategory: "Subcategorie",
    subCategories: "Subcategorieën",
    addCategory: "Categorie toevoegen",
    addSubCategory: "Subcategorie toevoegen",
    editSubCategory: "Subcategorie bewerken",
    editCategory: "Categorie bewerken",
    deleteCategory: "Categorie verwijderen",
    reviewCategory: "Categorie bekijken",
    categoryImages: "Categorie afbeeldingen",
    categoryImage: "Categorie afbeelding",
    subCategoryReview: "Subcategorie bekijken",
    subCategoryImages: "Subcategorie afbeeldingen",
    errorChangingVisibility:
      "Er is een fout opgetreden bij het wijzigen van de zichtbaarheid.",
    successChangingVisibility: "Zichtbaarheid succesvol gewijzigd.",
    successDeletingImage: "Afbeelding succesvol verwijderd.",
    errorDeletingImage: "Fout bij het verwijderen van de afbeelding.",
    addSubCategoryImages: "Subcategorie afbeeldingen toevoegen",
    categories: "Categorieën",
    categoriesAndImages: "Categorieën/Afbeeldingen",
    sendedCategoryImages: "Verzonden categorieafbeeldingen",
    uploadEditedImage: "Bewerkte afbeelding uploaden",
    downloadSendedImage: "Verzonden afbeelding downloaden",
    categoryTableTitle: {
      categoryId: "ID",
      categoryName: "Categorie Naam",
      categoryDescription: "Beschrijving",
      categorySerial: "Serieel",
      language: "Taal",
    },
    dealer: "Dealer",
    dealerTexts: {
      dealers: "Dealers",
      yes: "Ja",
      no: "Nee",
      addDealer: "Dealer toevoegen",
      deleteDealer: "Dealer verwijderen",
      deleteDealerMessage: "Weet u zeker dat u de dealer wilt verwijderen?",
      deleteDealerSuccess: "Dealer succesvol verwijderd.",
      deleteDealerError:
        "Er is een fout opgetreden bij het verwijderen van de dealer. Probeer het opnieuw.",
      search: "Zoeken",
      previous: "Vorige",
      next: "Volgende",
      dealerAddress: "Adres",
      dealerTableTitle: {
        dealerId: "ID",
        dealerAddress: "Adres",
        dealerPhone: "Telefoon",
        dealerName: "Dealer Naam",
      },
      dealerCity: "Stad",
      dealerCountry: "Land",
      dealerEmail: "E-mail",
      dealerName: "Dealer Naam",
      dealerPhone: "Telefoon",
      dealerPostalCode: "Postcode",
      dealerCountry: "Land",
      addZipCode: "Postcode toevoegen",
      dealerSave: "Opslaan",
      dealerCancel: "Annuleren",
      dealerState: "Staat",
      dealerTitle: "Dealers",
      editDealer: "Dealer bewerken",
      updateDealer: "Dealer bijwerken",
      dealerSaveError:
        "Er is een fout opgetreden bij het toevoegen van de dealer. Probeer het opnieuw.",
      dealerUpdateError:
        "Er is een fout opgetreden bij het bijwerken van de dealer. Probeer het opnieuw.",
      dealerSaveSuccess: "Dealer succesvol toegevoegd.",
      dealerUpdateSuccess: "Dealer succesvol bijgewerkt.",
    },
    analysis: "Analyse",
    payments: "Betalingen",
    users: "Gebruikers",
    saveStatus: {
      imageSaveError:
        "Er is een fout opgetreden bij het opslaan van de afbeelding. Probeer het opnieuw.",
      userSaveError:
        "Er is een fout opgetreden bij het opslaan van de gebruiker. Probeer het opnieuw.",
      relationSaveError:
        "Er is een fout opgetreden bij het opslaan van de relatie. Probeer het opnieuw.",
      userSaveSuccess: "Gebruiker succesvol toegevoegd.",
      userUpdateSuccess: "Gebruiker succesvol bijgewerkt.",
      deleteUserMessage: "Weet u zeker dat u de gebruiker wilt verwijderen?",
      deleteUserSuccess: "Gebruiker succesvol verwijderd.",
      deleteUserError:
        "Er is een fout opgetreden bij het verwijderen van de gebruiker. Probeer het opnieuw.",
    },
    userTableTitle: {
      id: "ID",
      name: "Naam",
      surname: "Achternaam",
      email: "E-mail",
      companyName: "Bedrijfsnaam",
      companyNumber: "Bedrijfsnummer",
      role: "Rol",
      freeTrial: "Gratis proefperiode",
      userName: "Gebruikersnaam",
      userImage: "Gebruikersafbeelding",
      userTaxNumber: "BTW-nummer",
      userPassword: "Wachtwoord",
      userBackgrondImages: "Gebruikersachtergrondafbeeldingen",
      userRegistered: "Registratiedatum",
      phone: "Telefoon",
      editUser: "Gebruiker bewerken",
      addUser: "Gebruiker toevoegen",
      updateUser: "Gebruiker bijwerken",
      deleteUser: "Gebruiker verwijderen",
      userIBAN: "IBAN",
      userAccountNumber: "Bankrekeningnummer",
      userBankName: "Banknaam",
      userBankAccountOwnerName: "Bankrekening eigenaar naam",
      userBankAccountOwnerSurname: "Bankrekening eigenaar achternaam",
      userZipCode: "Postcode",
    },
    userModalTable: {
      userDetails: "Gebruikersdetails",
      userInformation: "Gebruikersinformatie",
      userBackgrondImages: "Achtergrondafbeeldingen",
      id: "ID",
      name: "Naam",
      surname: "Achternaam",
      userName: "Gebruikersnaam",
      password: "Wachtwoord",
      email: "E-mail",
      phone: "Telefoon",
      role: "Rol",
      companyName: "Bedrijfsnaam",
      freeTrial: "Gratis proefperiode",
      freeTrialYes: "Ja",
      freeTrialNo: "Nee",
      registerDate: "Registratiedatum",
      userBankInfo: "Bankgegevens",
      userTaxNumber: "BTW-nummer",
      userIBAN: "IBAN",
      userBankAccountOwner: "Bankrekening eigenaar",
      userAccountNumber: "Bankrekeningnummer",
      userBankName: "Banknaam",
      dealerInfo: "Dealerinformatie",
      dealerName: "Dealer Naam",
      dealerAddress: "Adres",
      dealerPhone: "Telefoon",
      dealerPostalCode: "Postcode",
    },

    paymentTableTitle: {
      paymentId: "ID",
      userId: "Gebruikers ID",
      userName: "Gebruikersnaam",
      paymentAmount: "Betalingsbedrag",
      paymentStatus: "Betalingsstatus",
      paymentDate: "Betalingsdatum",
      paymentDescription: "Betalingsbeschrijving",
      description: "Beschrijving",
    },
    loginPage: {
      username: "Gebruikersnaam",
      password: "Wachtwoord",
      login: "Inloggen",
      loginSuccess: "Inloggen gelukt",
      loginError: "Inloggen mislukt",
      loginWarning:
        "Gebruikersnaam of wachtwoord is onjuist. Probeer het opnieuw.",
      selectLanguage: "Selecteer taal",
    },
    supportRequests: "Ondersteuningsverzoeken",
    sendedImages: "Verzonden afbeeldingen",
    userBackgrondImages: "Achtergrondafbeeldingen",
    sendedProducts: "Verzonden producten",
    sendedProductsFromUsers: "Verzonden producten van gebruikers",
    Images: "Afbeeldingen",
    search: "Zoeken",
    previous: "Vorige",
    next: "Volgende",
    selectLanguages: "Selecteer taal",
    addBusiness: "Bedrijf toevoegen",
    editBusiness: "Bewerk bedrijf",
    addProduct: "Product toevoegen",
    editProduct: "Bewerk product",
    deleteProduct: "Product verwijderen",
    noImageFound: "Geen afbeelding gevonden",
    showProductImage: "Productafbeelding weergeven",
    showImages: "Afbeeldingen weergeven",
    productImageSize: "De productafbeelding moet 1000x1000 pixels zijn.",
    addImage: "Afbeelding toevoegen",
    productName: "Productnaam",
    yes: "Ja",
    no: "Nee",
  },
  // Add more languages as needed
};
